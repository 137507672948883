import React, { useState } from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import { Link } from 'gatsby';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Section from '../components/Section/Section';
import Headline from '../components/Headline/Headline';

import imageRoentgen from '../images/Digitales Roentgen-min.jpg';
import imageGang from '../images/Ganganalyse (unsplash)-min.jpg';
import imageLabor from '../images/Labor (unsplash)-min.jpg';

const LeistungenPage = () => (
  <Layout headerWhite>
    <SEO title="Diagnostik | Orthopädie Dr. Schubert" description="Orthopädische Diagnostik ✓ Mehr als 20 Jahre Berufserfahrung ✓ Digitales Röntgen ✓ MRT ✓ Labor ✓ Ganganalyse ✓ Termine online vereinbaren ✓" />
    <Section dark>
      <Container>
        <Headline h1 light subtitle="Orthopädie Dr. Schubert">Diagnostik</Headline>

        <Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Digitales Röntgen -
                {' '}
                <i>nur, wenn es notwendig ist</i>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Row>
                <Col xs={12} sm={6}>
                  <p>
                    Wir versuchen, soweit es möglich ist, auf Röntgenuntersuchungen zu verzichten.
                    <br />
                    <br />
                    Sollte es für eine genaue Diagnose und Therapieplanung dennoch notwendig sein, Röntgenbilder anzufertigen, ist unsere Praxis mit einem digitalen Hightech-Gerät der neuesten Generation ausgestattet.
                    <br />
                    <br />
                    Das bedeutet für Sie,
                    <ul>
                      <li>eine deutlich geringere Strahlenbelastung gegenüber herkömmlichen analogen Geräten</li>
                      <li>die Bilder sind in allen Zimmern aufrufbar und werden Ihnen verständlich am Monitor erklärt</li>
                      <li>das Aushändigen der Bilder ist jederzeit möglich</li>
                    </ul>
                    Und wir tun etwas für die Umwelt - keine chemischen Abfälle bei der Bilderzeugung.

                  </p>

                </Col>
                <Col xs={12} sm={6}>
                  <img src={imageRoentgen} alt="Roentgen" />
                </Col>
              </Row>

            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>

                Ultraschall -
                {' '}
                <i>mehr sehen und sicher navigieren</i>

              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Ultraschall bietet eine schnelle, unkomplizierte und völlig strahlungsfreie Möglichkeit der Diagnostik. So können wir Gelenke, Muskeln und Sehnen darstellen, Verletzungen erkennen und notwendige Therapien einleiten.
                Die Untersuchungen sind - ganz ohne Strahlung und grossen Aufwand - beliebig oft wiederholbar, um den Behandlungsverlauf zu kontrollieren.
                <br />
                <br />
                Zusätzlich sind wir in der Lage, auch in Bewegung zu untersuchen. Spritzen lassen sich so exakt unter Sicht steuern. Wir sehen genau, wo die Nadel hinführt und bringen Medikamente/Wirkstoffe da hin, wo sie wirken sollen.
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>

                Ganganalyse und Pedographie -
                {' '}
                <i>die Füsse sind das Fundament des Körpers</i>

              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Optische und digitale  Fussmessung (Fachbegriff: Pedographie) im Verbund mit einer 3 D Ganganalyse liefern uns detaillierte Aussagen über Ihren Fuss, seine Belastungszonen und Fehlstellungen. Wir sehen muskuläre Schwächen und erhalten dadurch Hinweise auf statische Fehlhaltungen des Körpers.
                <br />
                <br />
                Sie laufen bei der Untersuchung mehrmals über ein digitales Feld. Scansysteme werten die Bilder aus. Die zusätzliche Foto-, Videoanalyse erfasst den gesamten Bewegungsablauf.
                <br />
                <br />
                Sollte eine Einlagenversorgung notwendig sein, können diese passgenau gefertigt werden.
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>

                Labor -
                {' '}
                <i>Blut, ein Spiegel des Körpers</i>

              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Row>
                <Col xs={12} sm={6}>
                  <p>
                    Bei vielen Erkrankungen sind Untersuchungen des Venenblutes oder der Gelenkflüssigkeit unerlässlich.
                    <br />
                    <br />
                    Sei es in der Diagnostik von Rheuma, Osteoporose oder auch nur, um die Stärke einer Entzündung festzustellen.
                    <br />
                    <br />
                    Hierfür arbeiten wir mit ortsansässigen Labormedizinern zusammen, um so auf dem schnellstmöglichen Weg Ihre Ergebnisse zu erhalten.
                    <br />
                    <br />
                    Spezielle Untersuchungen werden auf Nachfrage gerne durchgeführt. Sprechen Sie uns an.
                  </p>
                </Col>
                <Col xs={12} sm={6}>
                  <img src={imageLabor} alt="Labor" />
                </Col>

              </Row>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>

                MRT/Kernspin -
                {' '}
                <i>Schnittbilder des Körpers</i>

              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>

                Die Magnetresonanztomographie MRT - auch als Kernspintomographie bezeichnet - ist eine auf Magnetfeldern basierte Untersuchungsmethode. Sie kann den menschlichen Körper schonend ohne Strahlenbelastung detailgenau im Schnittbild darstellen.
                <br />
                <br />
                Diese Untersuchung wird in nahegelegenen radiologischen Instituten durchgeführt. Wir helfen Ihnen gerne bei der Terminvergabe.

              </p>
            </AccordionItemPanel>
          </AccordionItem>

        </Accordion>
      </Container>
    </Section>
  </Layout>
);

export default LeistungenPage;
